<template>
<div id="home" ref="home">
  <div class="homeWrap">
    <div class="search-box" v-show="userInfo.type!=2">
      <el-input class="searchCt" v-model="searchValue" placeholder="搜索职位、公司"></el-input>
      <div class="search-icon" @click="jumpPositionSearch">
        搜索
      </div>
    </div>
    <div class="hot-list" v-show="userInfo.type!=2">
      <span>热门职位：</span>
      <label v-for="(item,index) in hotList" @click="jumpHot(item)" :key="index" v-show="index<9">{{item.positionCodeName}}</label>
    </div>
    <div class="search-box" v-show="userInfo.type==2">
      <el-input v-model="searchValue" class="searchCt" placeholder="搜索人才、简历"></el-input>
      <div class="search-icon" @click="jumpComapnySearch">
        搜索
      </div>
    </div>
    <div class="job-menu" v-loading="loadSort" @mouseleave="outClassify">
      <div class="jm-title">全部分类</div>
      <ul>
        <li @mouseover="selectClassify(item)" v-for="(item,key) in positionList" :key="key">
          <span>{{item.name}}</span>
          <p><label v-for="(s,index) in item.subLevelModelList" :key="index" @click="jumpSearch(s.code)">{{s.name}}</label></p>
          <img src="@/assets/imgs/arrow-right.png" />
        </li>
      </ul>
      <div class="jm-bottom">
        <div class="page-num">
          <span>{{pCurrent}} </span><label>/ {{pTotal}}</label>
        </div>
        <div class="prev">
          <span @click="next"><img src="@/assets/imgs/left-icon.png"></span>
          <label @click="prev"><img src="@/assets/imgs/right-icon.png"></label>
        </div>
      </div>
    </div>
    <div class="menu-box" v-show="showList" @mouseleave="outClassifyList" @mouseover="classShow">
      <div class="menu-wrap">
        <div class="menu-title">{{positionInfo.name}}</div>
        <div class="menu-item" v-for="(item,index) in positionInfo.subLevelModelList" :key="index">
          <p>{{item.name}}</p>
          <ul>
            <li v-for="(s,key) in item.subLevelModelList" :key="key" @click="jumpSearch(s.code)">{{s.name}}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="banner" v-show="!showList" v-loading="loadBanner">
      <div class="banner-top">
        <el-carousel height="243px">
          <el-carousel-item v-for="(item,index) in bannerList" :key="index">
            <img :src="item.picUrl" @click="openUrl(item)" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="banner-bottom">
        <img :src="bannerLeft.picUrl" @click="openUrl(bannerLeft)">
        <img :src="bannerRight.picUrl" @click="openUrl(bannerRight)" class="bt-pic">
      </div>
    </div>
    <div class="star" v-show="oneAdvertisingSpaceList.length>0">
      <div class="star-item" v-for="(item,index) in oneAdvertisingSpaceList" :key="index" @click="jumpCompany(item)">
        <img :src="item.advertisementUrl" alt="">
      </div>
    </div>
    <div class="star starSencond" v-show="twoAdvertisingSpaceList.length>0">
      <div class="star-item" v-for="(item,index) in twoAdvertisingSpaceList" :key="index" @click="jumpCompany(item)">
        <img :src="item.advertisementUrl" alt="">
      </div>
    </div>
    <div class="starThree" v-show="threeAdvertisingSpaceList.length>0">
       <ul>
         <li v-for="(item,index) in threeAdvertisingSpaceList" :key="index"  @click="jumpCompany(item)">
            <img :src="item.advertisementUrl" alt="">
         </li>
       </ul>     
    </div>
  </div>
  <div class="mainBox">
    <div class="mainWrap">
      <div class="position-box" v-show="userInfo.type==1||userInfo.type==3">
        <div class="ps-header">
          <ul>
            <li @click="togSub(1)" :class="{'curP':subP==1}">推荐职位</li>
            <li @click="togSub(2)" :class="{'curP':subP==2}">最新职位</li>
            <li @click="togSub(3)" :class="{'curP':subP==3}">急聘职位</li>
          </ul>
        </div>
        <div class="ps-list" v-loading="loadPosition">
          <div class="ps-item" v-show="index<6" v-for="(item,index) in jobList" :key="index" @click="jumpPosition(item)">
            <div class="ps-item-top">
              <span>{{item.positionName}}</span>
              <label v-show="subP==3&&item.helpWanted==1">急聘</label>
              <label v-show="subP==2" class="new-btn">NEW</label>
              <p>
                <em>
                  {{item.minimumWage}}<em v-show="item.maximumSalary">-{{item.maximumSalary}}</em>
                </em>
              </p>
            </div>
            <div class="ps-item-mid">
              <p>
                <label v-show="item.areaName"><img style="width:14px" src="@/assets/imgs/location-icon.png">{{item.areaName}}</label>
                <label><img src="@/assets/imgs/edu-icon.png">{{item.minimumEducationName}}</label>
                <label><img src="@/assets/imgs/time-icon.png">{{item.workExperienceName}}</label>
              </p>
            </div>
            <div class="ps-item-ft">
              <img :src="item.enterpriseLogo" />
              <span>{{item.publisherName}} <label v-show="item.position">· {{item.position}}</label></span>
              <div class="updateTime">
                {{item.updateTime}}
              </div>
            </div>
          </div>
        </div>
        <router-link :to="{path:'/positionList'}">
          <div class="moreP">查看更多</div>
        </router-link>
        <div class="ps-header" v-show="(userInfo.type==1||userInfo.type==3)&&popularList.length>0">
          <ul>
            <li class="curP">热门公司榜</li>
          </ul>
        </div>
        <div class="c-list" v-show="(userInfo.type==1||userInfo.type==3)&&popularList.length>0">
          <div class="c-item" v-show="index<8" v-for="(item,index) in popularList" :key="index" @click="jumpCompany(item)">
            <div class="c-info-top">
              <img :src="item.enterpriseLogo" />
              <p>
                <span>{{item.enterpriseName}}</span>
                {{item.enterpriseNatureName}} <label v-show="item.enterpriseNatureName">|</label> {{item.scaleName}}<label v-show="item.industryName">|</label> {{item.industryName}}
              </p>
            </div>
            <div class="p-info" v-for="(p,key) in item.positionInfoList" :key="key" v-show="key<3">
              <span>{{p.positionName}}</span>
              <label>
                {{p.minimumWage}}<i v-show="p.maximumSalary">-{{p.maximumSalary}}</i>
              </label>
              <p>
                <em v-show="p.workExperienceName">{{p.workExperienceName}}</em>
                <em v-show="p.minimumEducationName">{{p.minimumEducationName}}</em>
              </p>
            </div>
            <div class="moreC">查看更多职位</div>
          </div>
          <router-link :to="{path:'/popularList'}">
            <div class="moreP">查看更多</div>
          </router-link>
        </div>
      </div>
      <!-- 简历列表 -->
      <div class="position-box" v-show="userInfo.type==2||userInfo.type==3">
        <div class="ps-header">
          <ul>
            <li @click="togRub(1)" :class="{'curP':subR==1}">推荐简历<span></span></li>
            <li @click="togRub(2)" :class="{'curP':subR==2}">最新简历<span></span></li>
          </ul>
        </div>
        <div class="resume-list" v-loading="loadResume">
          <div class="resume-item" v-for="(item,index) in resumeList" v-show="index<8" :key="index" @click="jumpResume(item)">
            <div class="resume-top">
              <img class="avator" :src="item.recruitUserInfo.headSculpture" />
              <span>{{item.recruitUserInfo.userName}}</span>
              <label v-show="item.recruitUserInfo.topping==1">置顶</label>
            </div>
            <div class="resume-mid">
              <label>
                <img src="@/assets/imgs/edu-icon.png">
                <span>{{item.resumeBasicInfo.educationName}}</span>
              </label>
              <label>
                <img src="@/assets/imgs/time-icon.png">
                <span>{{item.resumeBasicInfo.workExperienceName}}</span>
              </label>
              <label>
                <img src="@/assets/imgs/personal/money_bag.png" />
                <span>
                  {{item.resumeJobDesire.minimumWage}}<label v-show="item.resumeJobDesire.maximumSalary">-{{item.resumeJobDesire.maximumSalary}}</label>
                </span>
              </label>
            </div>
            <h3>求职期望： {{item.resumeJobDesire.workFunctionName}}</h3>
            <p>{{item.resumeOtherInfo.selfEvaluation}}</p>
          </div>
        </div>
        <router-link :to="{path:'/resumeList'}">
          <div class="moreP">查看更多</div>
        </router-link>
      </div>
    </div>
  </div>
  <div class="reBanner">
    <div class="joinus">
       <img src="@/assets/imgs/joinus.png" />
    </div>
  </div>
  <!-- 服务之窗 -->
  <div class="service-box">
    <div class="service-wrap">
      <div class="service-header">
        <span>服务之窗</span>
      </div>
      <div class="service-item">
        <div class="sv-top">
          <img src="@/assets/imgs/serOne.png">
          <span>法律法规</span>
          <label @click="jumpLaws(1)">查看更多 ></label>
        </div>
        <div class="sv-ct">
          <p v-for="(item,index) in examinatOneList" @click="jumpLawsDetail(item)" :key="index">
            <span>· </span>{{item.title}}
          </p>
        </div>
      </div>
      <div class="service-item  service-item-right">
        <div class="sv-top">
          <img src="@/assets/imgs/zhaopin.png">
          <span style="color:#FF2400">招聘须知</span>
          <label @click="jumpLaws(2)">查看更多 ></label>
        </div>
        <div class="sv-ct">
          <p v-for="(item,index) in examinatTwoList" :key="index" @click="jumpLawsDetail(item)">
            <span>· </span>{{item.title}}
          </p>
        </div>
      </div>
      <div class="service-item">
        <div class="sv-top">
          <img  src="@/assets/imgs/skill.png">
          <span style="color:#E9A31D">求职技巧</span>
          <label @click="jumpLaws(3)">查看更多 ></label>
        </div>
        <div class="sv-ct">
          <p v-for="(item,index) in examinatThreeList" @click="jumpLawsDetail(item)" :key="index">
            <span>· </span>{{item.title}}
          </p>
        </div>
      </div>
      <div class="service-item service-item-right">
        <div class="sv-top">
          <img style="width:25px" src="@/assets/imgs/case.png">
          <span style="color:#6E1FD8">劳务纠纷案例</span>
          <label @click="jumpLaws(4)">查看更多 ></label>
        </div>
        <div class="sv-ct">
          <p v-for="(item,index) in examinatFourList" @click="jumpLawsDetail(item)" :key="index" v-show="index<5">
            <span>· </span>{{item.title}}
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- 公司动态 -->
  <div class="trends">
    <div class="trendsWrap">
      <div class="trends-title">
        <span>公司动态</span>
         <router-link :to="{path:'/trendList'}">
           <label>查看更多 ></label>
         </router-link>
      </div>
    
      <div class="trends-item" v-for="(item,index) in companyDynamicsList" :key="index" @click="jumpTrend(item)">
        <img :src="item.homeImage">
        <div class="td-ct">
          <h4>{{item.title}}</h4>
          <p class="td-des" v-html="item.briefIntroduction"></p>
          <span>{{item.updateTime||item.createTime}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="links">
    <div class="linksWrap">
       <div class="links-header">
        <span>友情链接</span>
      </div>
      <ul>
        <li v-for="(item,index) in linkList" :key="index" @click="jumpLink(item)">
         {{item.linkName}}
        </li>
      </ul>
    </div>
  </div>
  <div class="siderBar">
    <div class="siderBar-wrap">
      <div class="siderBar-item">
        <img width="25px" @click="siderTop" height="28px" src="@/assets/imgs/s-top.png" />
      </div>
      <div class="siderBar-item" @mouseover="overCustor" @mouseleave="outCustor">
        <img width="29px" @mouseover="overCustor" height="27px" src="@/assets/imgs/s-custor.png" />
      </div>
      <div class="siderBar-item">
        <img width="29px" height="29px" @click="jumpChat" src="@/assets/imgs/s-interview.png" />
      </div>
      <div class="custorBox" v-show="mouserCustor" @mouseover="overCustor" @mouseleave="outCustor">
        <div class="custorCt">
          <div class="triangle"></div>
          <div class="contact">
            <img src="@/assets/imgs/contact.png" />
            <span>联系客服</span>
          </div>
          <div class="custorPhone">
            <img src="@/assets/imgs/custom.png" />
            <p>
              <span>客服电话</span>
              <span>{{customerInfo.customerServicePhone}}</span>
            </p>
          </div>
          <div class="wxCode">
            <img :src="customerInfo.customerServiceWeChat" />
          </div>
          <div class="workTime">
            <span>{{customerInfo.workingHours}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <resume-detail :resumeDialog="resumeDialog" :userId="userId" @closeDialog="closeDialog"></resume-detail>
</div>
</template>

<script>
import resumeDetail from './resume/resumeDetail.vue'
import {getFriendlyLinks,getAdvertisingSpaceList, getVerifyResumeFilling, searchTermsPosition, verifyRealName, getPopularEnterprises, getPosition, getExaminationListTwo, getExaminationListThree, getExaminationListFour, getCompanyDynamicsList, getExaminationList, getResumeList, getAdvert, getJobApplicantPositionList, getDictData } from "@/api/index.js";
export default {
  name: "home",
  components: {
    'resume-detail': resumeDetail,
  },
  data() {
    return {
      resumeDialog: false,
      bannerLeft: {},
      bannerRight: {},
      searchValue: '',
      customName: '',
      showList: false,
      subIndex: 0,
      bannerList: [],
      subP: 1,
      positionList: [],
      positionListMap: [],
      positionInfo: {},
      pTotal: 0,
      pCurrent: 1,
      jobList: [],
      benefitsList: [],
      benefitsMap: [],
      userInfo: { type: 3 },
      resumeList: [],
      userId: '',
      examinatOneList: [],
      examinatTwoList: [],
      examinatThreeList: [],
      examinatFourList: [],
      companyDynamicsList: [],
      hotList: [],
      loadPosition: true,
      loadResume: true,
      loadBanner: true,
      loadSort: true,
      subR: 1,
      popularList: [],
      isResumeFill: true,
      mouserCustor: false,
      customerInfo: {},
      linkList:[],
      oneAdvertisingSpaceList:[],
      twoAdvertisingSpaceList:[],
      threeAdvertisingSpaceList:[]
    }
  },
  mounted() {
    this.getPosition()
    if (localStorage.getItem('userInfo')) {
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
      if (this.userInfo.type == 2) {
        this.verifyRealName()
      }
    }
    this.getDictData('company_benefits')
    this.getAdvert()
    this.getExaminationList(1)
    this.getExaminationListTwo(2)
    this.getExaminationListThree(3)
    this.getExaminationListFour(4)
    this.getCompanyDynamicsList()
    this.searchTermsPosition()
    this.getPopularEnterprises()
    this.getVerifyResumeFilling()
    this.getFriendlyLinks()
    this.getAdvertisingSpaceList()
    this.customerInfo = JSON.parse(localStorage.getItem('companyInfo'))
  },
  methods: {
    getAdvertisingSpaceList(){
      getAdvertisingSpaceList().then(res=>{
        this.oneAdvertisingSpaceList = res.data.oneAdvertisingSpaceList
        this.twoAdvertisingSpaceList = res.data.twoAdvertisingSpaceList
        this.threeAdvertisingSpaceList = res.data.threeAdvertisingSpaceList
        console.log(res)
      })
    },
    jumpLink(item){
       window.open(item.linkAddress, '_blank');
    },
    getFriendlyLinks(){
      getFriendlyLinks().then(res=>{
        this.linkList = res.rows
      })
    },
    overCustor() {
      this.mouserCustor = true
    },
    outCustor() {
      this.mouserCustor = false
    },
    verifyRealName() {
      verifyRealName().then(res => {
        if (res.isCertification == 0) {
          this.$router.push({
            path: '/addContacts'
          })
          this.$message({
            message: '请先绑定企业！',
            type: 'warning'
          })
        }
      })
    },
    getPopularEnterprises() {
      getPopularEnterprises({
        page: 1,
        size: 6
      }).then(res => {
        this.popularList = res.rows
      })
    },
    searchTermsPosition() {
      searchTermsPosition().then(res => {
        this.hotList = res.rows
        console.log(res)
      })
    },
    jumpCompany(item) {
      this.$router.push({
        path: '/companyDetail',
        query: {
          enterpriseId: item.enterpriseId
        }
      })
    },
    jumpSearch(code) {
      if (this.userInfo.type == 2) {
        this.$router.push({
          path: '/resumeList',
          query: {
            expectedPosition: code
          }
        })
      } else {
        this.$router.push({
          path: '/positionList',
          query: {
            expectedPosition: code
          }
        })
      }
    },
    jumpChat() {
      if (this.userInfo.type == 3) {
        this.$router.push({
          path: '/login',
        })
      } else {
        this.$router.push({
          path: '/chat'
        })
      }
    },
    jumpHot(item) {
      this.$router.push({
        path: '/positionList',
        query: {
          expectedPosition: item.positionCode
        }
      })
    },
    jumpPositionSearch() {
      this.$router.push({
        path: '/positionList',
        query: {
          searchValue: this.searchValue
        }
      })
    },
    jumpComapnySearch() {
      this.$router.push({
        path: '/resumeList',
        query: {
          searchValue: this.searchValue
        }
      })
    },
    openUrl(value) {
      console.log(value)
      if (value.jobFairs == 'Y') {
        this.$router.push({
          path: '/jobFairs'
        })
      } else if (value.talentIntroduction == 'Y') {
        this.$router.push({
          path: '/joinUs',
          query: {
            id: value.id
          }
        })
      } else {
        window.open(value.linkUrl, '_blank');
      }
    },
    jumpTrend(item) {
      this.$router.push({
        path: '/trendDetail',
        query: {
          id: item.id
        }
      })
    },
    getCompanyDynamicsList() {
      getCompanyDynamicsList({
        page: 1,
        size: 4
      }).then(res => {
        this.companyDynamicsList = res.rows
      })
    },
    jumpLawsDetail(item) {
      this.$router.push({
        path: '/lawsDetail',
        query: {
          id: item.id
        }
      })
    },
    getExaminationList(type) {
      getExaminationList({
        type: type,
        page: 1,
        size: 5
      }).then(res => {
        this.examinatOneList = res.rows
      })
    },
    getExaminationListTwo(type) {
      getExaminationListTwo({
        type: type,
        page: 1,
        size: 5
      }).then(res => {
        this.examinatTwoList = res.rows
      })
    },
    getExaminationListThree(type) {
      getExaminationListThree({
        type: type,
        page: 1,
        size: 5
      }).then(res => {
        this.examinatThreeList = res.rows
      })
    },
    getExaminationListFour(type) {
      getExaminationListFour({
        type: type,
        page: 1,
        size: 5
      }).then(res => {
        this.examinatFourList = res.rows
      })
    },
    jumpLaws(type) {
      this.$router.push({
        path: '/lawsList',
        query: {
          type: type
        }
      })
    },
    closeDialog() {
      this.resumeDialog = false
    },
    jumpResume(item) {
      if (this.userInfo.type == 3) {
        this.$router.push({
          path: '/login',
        })
      } else {
        this.resumeDialog = true
        this.userId = item.recruitUserInfo.id
      }
    },
    getResumeList() {
      this.loadResume = true
      getResumeList({
        queryCategory: this.subR
      }).then(res => {
        this.loadResume = false
        this.resumeList = res.rows
      })
    },
    jumpPosition(item) {
      if (this.userInfo.type == 3) {
        this.$router.push({
          path: '/login',
        })
      } else {
        if (this.isResumeFill) {
          this.$router.push({
            path: '/jobDetail',
            query: {
              positionId: item.id
            }
          })
        } else {
          this.$router.push({
            path: '/resume'
          })
        }
      }
    },
    getVerifyResumeFilling() {
      getVerifyResumeFilling().then(res => {
        this.isResumeFill = res.data
      })
    },
    jumpComapy(enterpriseId) {
      this.$router.push({
        path: '/companyDetail',
        query: {
          enterpriseId: enterpriseId
        }
      })
    },
    getDictData(dictType) {
      getDictData({ dictType: dictType }).then(res => {
        this.benefitsList = res.rows
        localStorage.setItem('benefitsList', JSON.stringify(res.rows))
        this.getJobApplicantPositionList()
      })
    },
    getAdvert() {
      this.banner = []
      this.loadBanner = true
      getAdvert({
        type: 1
      }).then(res => {
        this.loadBanner = false
        res.rows.forEach(item => {
          if (item.seat == 0) {
            this.bannerList.push(item)
          }
          if (item.seat == 1) {
            this.bannerLeft = item
          }
          if (item.seat == 2) {
            this.bannerRight = item
          }
        })
      })
    },
    getJobApplicantPositionList() {
      this.loadPosition = true
      getJobApplicantPositionList({
        queryCategory: this.subP
      }).then(res => {
        this.jobList = res.rows
        this.loadPosition = false
        this.jobList.forEach(item => {
          var benefits = item.materialBenefits.split(',')
          item.benefitsMap = []
          benefits.forEach(g => {
            this.benefitsList.forEach(s => {
              if (g == s.dictValue) {
                item.benefitsMap.push(s.dictLabel)
              }
            })
          })
        })
      })
    },
    getPosition() {
      this.loadSort = true
      getPosition().then(res => {
        this.loadSort = false
        this.getResumeList()
        this.positionListMap = res.rows
        this.positionList = res.rows
        localStorage.setItem('positionList', JSON.stringify(res.rows))
        this.pTotal = Math.ceil(res.total / 6)
      })
    },
    prev() {
      this.positionList = []
      this.positionListMap.forEach((item, index) => {
        if (index >= this.pCurrent * 6) {
          this.positionList.push(item)
        }
      })
      this.pCurrent++
      if (this.pCurrent > this.pTotal) {
        this.pCurrent = 1
        this.positionList = this.positionListMap
      }
    },
    next() {
      if (this.pCurrent > 1) {
        this.pCurrent--
        console.log(this.pCurrent)
        this.positionList = []
        this.positionListMap.forEach((item, index) => {
          if (index >= (this.pCurrent - 1) * 6) {
            this.positionList.push(item)
          }
        })
      }
    },
    siderTop() {
      document.documentElement.scrollTop = 0;
    },
    selectClassify(item) {
      this.positionInfo = item
      this.showList = true
    },
    togSub(index) {
      this.subP = index
      this.getJobApplicantPositionList()
    },
    togRub(index) {
      this.subR = index
      this.getResumeList()
    },
    classShow() {
      this.showList = true
    },
    outClassify() {
      this.showList = false
    },
    outClassifyList() {
      this.showList = false
    },
  }
};
</script>

<style lang="scss" scoped>
#home {
  width: 100%;
  min-height: 800px;
  background: linear-gradient(0deg, #F5F5F5 1%, #D6E9FF 100%);
  overflow: hidden;
}

.home-top {
  height: 150px;

  img {
    width: 100%;
    height: 150px;
  }
}

.homeWrap {
  width: 1200px;
  margin: 0 auto;
  padding-top: 24px;
}
.links{
  min-height: 100px;
  background: #fff;
  padding: 40px 0;
  .linksWrap{
    width: 1200px;
    margin: 0 auto;
  }
  .links-header{
    height: 40px;
    border-bottom: 1px solid #D9D9D9;
    font-size: 20px;
    span{
      display: inline-block;
      color: #0071fb;
      border-bottom: 2px solid #0071fb;
      padding-bottom: 13px;
    }
  }
  ul{
    padding: 30px 0;
    li{
      float: left;
      color: #222222;
      font-size: 14px;
      margin-right: 20px;
      cursor: pointer;
    }
    li:hover{
      color: #0071fb;
    }
  }
}
.star{
  width: 1200px;
  min-height: 121px;
  background: #D9E3ED;
  border-radius: 10px;
  margin: 0 auto 24px;
  clear: both;
  overflow: hidden;
  padding-bottom: 15px;
  .star-item{
    float: left;
    width: 373px;
    height: 106px;
    margin-left:15px;
     margin-top: 15px;
     overflow: hidden;
     background: #fff;
     cursor: pointer;
      border-radius: 6px;
    img{
      width: 200px;
      height: 70px;
      margin: 18px 70px;
    }
  }
}
.starSencond{
  background: none;
  margin: 0 auto;
  position: relative;
  width: 1220px;
  min-height: 120px;
  top: -20px;
  .star-item{
     width: 389px;
     margin-left: 0;
     margin-right: 15px;
  }
}
.starThree{
  position: relative;
  top: -20px;
  img{
    width: 1200px;
    height: 84px;
    margin-bottom: 15px;
    cursor: pointer;
  }
}
.mainBox {
  background: url('../assets/imgs/mainBg.png');
  background-size: cover;
  padding-bottom: 40px;

  .mainWrap {
    width: 1200px;
    margin: 0 auto;
  }
}

.hot-list {
  width: 902px;
  min-height: 45px;
  margin: 0 auto;

  span {
    color: #757575;
    font-size: 14px;
    display: inline-block;
    margin-right: 12px;
  }

  label {
    display: inline-block;
    background: rgba(255, 255, 255, .8);
    border-radius: 6px;
    color: #0071fb;
    font-size: 14px;
    line-height: 20px;
    padding: 2px 8px;
    margin-right: 12px;
    cursor: pointer;
  }
}

.job-menu {
  width: 480px;
  height: 365px;
  background: #FFFFFF;
  border-radius: 10px;
  float: left;
  padding: 29px 31px;
  margin-bottom: 24px;

  .jm-title {
    font-size: 20px;
    color: #999999;
    margin-bottom: 10px;
  }

  ul {
    height: 290px;
    border-bottom: 1px solid #ddd;
    overflow: hidden;
  }

  label:hover {

    color: #0077FF;

  }

  li {
    cursor: pointer;
    height: 47px;

    p {
      width: 340px;
      height: 41px;
      line-height: 41px;
      overflow: hidden;
      display: inline-block;
    }

    span {
      font-size: 16px;
      color: #222222;
      display: inline-block;
      position: relative;
      top: -16px;
      cursor: pointer;
    }

    label {
      color: #999999;
      display: inline-block;
      margin-left: 16px;
      cursor: pointer;
    }

    img {
      float: right;
      width: 9px;
      height: 10px;
      position: relative;
      top: 17px;
    }
  }

  .page-num {
    float: left;
    margin-top: 22px;
    font-size: 16px;
    color: #0077FF;
  }

  .prev {
    float: right;
    margin-top: 23px;

    span {
      width: 24px;
      height: 24px;
      display: inline-block;
      border-radius: 5px;
      background: #EBF1F8;
      overflow: hidden;
    }

    label {
      width: 24px;
      height: 24px;
      display: inline-block;
      border-radius: 5px;
      background: #EBF1F8;
      overflow: hidden;
      margin-left: 20px;
    }

    img {
      width: 6px;
      height: 12px;
      margin: 6px 9px;
    }
  }
}

.banner {
  float: left;

  .banner-top {
    margin-bottom: 10px;

    img {
      width: 648px;
      height: 243px;
      margin-left: 10px;
      margin-bottom: 4px;
      cursor: pointer;
      border-radius: 10px;
    }
  }

  .banner-bottom {
    margin-left: 10px;

    img {
      width: 321px;
      height: 170px;
      cursor: pointer;
    }

    .bt-pic {
      margin-left: 5px;
    }
  }
}

.reBanner {
  clear: both;
  background: #fff;
  padding:20px 0 40px 0;
 .joinus{
  width: 1200px;
  margin: 0 auto;
    img {
    height: 230px;
    width: 100%;
    margin-top: 20px;
  }
 }
}

.menu-box {
  float: left;

  .menu-wrap {
    margin-left: 7px;
    height: 365px;
    background: #FFFFFF;
    border-radius: 10px;
    width: 587px;
    padding: 29px 31px;
    margin-bottom: 30px;
    overflow: auto;
  }

  .menu-title {
    font-size: 16px;
    color: #222222;
    margin-bottom: 7px;
  }

  .menu-item {
    color: #999;
    font-size: 14px;

    p {
      width: 140px;
      float: left;
      color: #666;
      margin-top: 15px;
    }

    ul {
      width: 420px;
      min-height: 30px;
      float: left;
      border-bottom: 1px solid #eee;
      padding-top: 15px;
    }

    li {
      cursor: pointer;
      float: left;
      margin-right: 20px;
      margin-bottom: 12px;
    }

    li:hover {
      color: #0077FF;
    }
  }

}

.box-title {
  font-size: 32px;
  font-weight: 600;
  color: #222;
  clear: both;
  text-align: center;
  margin-bottom: 25px;
  margin-top: 40px;
}

.position-box {
  clear: both;

  .ps-header {
    height: 50px;
    line-height: 50px;
    padding-left: 20px;
    border-bottom: 1px solid #e6e6e6;

    ul {
      width: 600px;
      float: left;
    }

    li {
      display: inline-block;
      text-align: center;
      cursor: pointer;
      color: #666;
      margin-right: 32px;
      font-size: 17px;
      transition: all .2s linear;
      margin-right: 60px;
    }

  }

  .curP {
    color: #0071fb !important;
    border-bottom: 2px solid #0071fb;
    // span{
    //     width: 28px;
    //     height: 3px;
    //     background: linear-gradient(90deg,#0071fb 0,#2e19cb 100%);
    //     border-radius: 2px;
    //    display: block;
    //    text-align: center;
    //    margin-left: 18px;
    // }
  }

  .ps-list {
    width: 1215px;
    overflow: hidden;

    .ps-item {
      width: 350px;
      min-height: 143px;
      background: #FFFFFF;
      border-radius: 10px;
      float: left;
      padding: 20px 20px 0;
      margin-top: 13px;
      margin-right: 15px;
      cursor: pointer;
    }

    .ps-item-top {
      span {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #222222;
      }

      label {
        display: inline-block;
        min-width: 41px;
        height: 21px;
        line-height: 21px;
        text-align: center;
        border: 1px solid #FF2400;
        border-radius: 2px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FF2400;
        margin-left: 9px;
      }

      .new-btn {
        border: 1px solid #F99746;
        color: #F99746;
      }

      p {
        font-size: 16px;
        color: #FF2400;
        float: right;

        em {
          font-style: normal;
        }
      }
    }

    .ps-item-mid {
      margin-top: 14px;

      span {
        min-width: 40px;
        display: inline-block;
        height: 25px;
        line-height: 25px;
        text-align: center;
        background: #E5F1FF;
        font-size: 14px;
        color: #0077FF;
        ;
        border-radius: 2px;
        margin-right: 10px;
        padding: 0 8px
      }

      p {
        margin-top: 16px;
        margin-bottom: 16px;

        label,
        em {
          font-size: 14px;
          color: #999999;
          display: inline-block;
          min-width: 60px;
          margin-right: 25px;
          font-style: normal;
        }

        img {
          position: relative;
          top: 3px;
          width: 18px;
          height: 18px;
          margin-right: 5px;
        }

      }
    }

    .ps-item-ft {
      height: 50px;
      border-top: 1px solid #ddd;
      cursor: pointer;

      img {
        width: 41px;
        height: 41px;
        border-radius: 50%;
        margin-top: 9px;
        margin-right: 10px;
      }

      span {
        font-size: 16px;
        position: relative;
        bottom: 13px;
        font-weight: 500;
        color: #222222;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 142px;
      }

      p {
        float: right;
        font-size: 14px;
        color: #999999;
        margin-top: 22px;
      }
    }
    .updateTime{
      float: right;
      margin-top: 22px;
      color: #999999;
      font-size: 14px;
    }
  }
}

.resume-list {
  width: 1215px;
  margin-top: 15px;
  overflow: hidden;

  .resume-item {
    width: 247px;
    height:190px;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 20px;
    float: left;
    margin-right: 13px;
    cursor: pointer;
    margin-bottom: 13px;
    position: relative;
    overflow: hidden;
  }

  .resume-top {
    text-align: center;
    label {
      display: inline-block;
      width: 41px;
      height: 21px;
      line-height: 21px;
      text-align: center;
      border: 1px solid #FF2400;
      border-radius: 2px;
      color: #FF2400;
      font-size: 14px;
      margin-left: 15px;
    }
    .avator {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: block;
      margin: 0 auto 14px;
    }
  }

  h3 {
    font-weight: 500;
    font-size: 14px;
    color: #222222;
    border-top: 1px solid #eee;
    padding-top: 12px;
    margin-top: 12px;
  }

  p {
    font-weight: 500;
    font-size: 14px;
    color: #222222;
    line-height: 20px;
    letter-spacing: 1px;
    margin-top: 8px;
    color: #999;
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

  }

  .resume-mid {
    clear: both;
    margin-top: 13px;
    text-align: center;

    label {
      font-size: 13px;
      color: #999999;
    }

    span {
      display: inline-block;
      margin-right: 20px;
    }

    img {
      position: relative;
      top: 3px;
      width: 18px;
      height: 18px;
      margin-right: 5px;
    }

    em {
      font-style: normal;
      display: inline-block;
      margin-left: -4px;
    }
  }
}

.moreP {
  width: 390px;
  height: 51px;
  clear: both;
  margin: 0 auto;
  background: #CDE0F7;
  border-radius: 5px;
  font-size: 16px;
  color: #0077FF;
  line-height: 51px;
  text-align: center;
  cursor: pointer;
  margin: 20px auto 10px;
}

.moreP:hover {
  background: #0077FF;
  color: #fff;
}

.c-list {
  margin-top: 29px;
  width: 1215px;

  .c-item {
    width: 290px;
    height: 335px;
    background: #FFFFFF;
    border-radius: 10px;
    float: left;
    margin-right: 13px;
    margin-bottom: 13px;
    position: relative;
    cursor: pointer;

    .c-info-top {
      width: 290px;
      height: 60px;
      background: linear-gradient(0deg, #FFFFFF 1%, #D6E9FF 100%);
      border-radius: 10px 10px 0px 0px;

      img {
        width: 45px;
        height: 45px;
        position: relative;
        margin-right: 10px;
        margin-left: 10px;
        margin-top: 10px;
        border-radius: 10px;
        float: left;
      }

      p {
        float: left;
        margin-top: 12px;
        font-size: 12px;
        font-weight: 400;
        color: #999999;

      }

      span {
        display: block;
        font-size: 14px;
        font-weight: 500;
        color: #222222;
        margin-bottom: 5px;
      }
    }

    .p-info {
      padding: 0 20px;
      margin: 14px 0;

      label {
        float: right;
        font-size: 15px;
        color: #FF2400;
        display: block;
        margin-top:5px;
        i{
          font-style: normal;
        }
      }

      span {
        font-size: 15px;
        font-weight: 500;
        color: #222222;
      }

      em {
        display: inline-block;
        min-width: 60px;
        height: 23px;
        line-height: 23px;
        text-align: center;
        font-size: 13px;
        color: #666;
        padding: 0 6px;
        background: #F5F5F5;
        margin-right: 10px;
        font-style: normal;
        margin-top: 9px;
        border-radius: 4px;
      }
    }
  }

  .moreC {
    clear: both;
    width: 201px;
    height: 36px;
    position: absolute;
    line-height: 36px;
    text-align: center;
    border: 1px solid #0077FF;
    border-radius: 5px;
    font-size: 14px;
    color: #0077FF;
    cursor: pointer;
    bottom: 20px;
    margin-left:50px;
  }

  .moreC:hover {
    background: #0077FF;
    color: #fff;

  }
}

.service-box {
  height: 730px;
  overflow: hidden;
 background: url('../assets/imgs/serverBg.png');
 background-size: cover;
  .service-wrap{
    width: 1200px;
    margin: 0 auto;
  }

  .service-header {
    border-bottom: 1px solid #6D7884 ;
    font-size: 22px;
    font-weight: 500;
    color: #FFFFFF;
    margin: 30px 0;
    padding-bottom: 20px;
    span{
      padding-bottom: 21px;
      border-bottom: 2px solid #fff;
    }
  }

  .service-item {
    width: 552px;
    height: 290px;
    background: #FFFFFF;
    border-radius: 10px;
    float: left;
    padding: 0 20px;
    margin-right: 16px;
    margin-bottom: 16px;
  }

  .sv-top {
    height: 55px;
    border-bottom: 1px solid #ddd;

    img {
      width: 20px;
      height: 20px;
      margin-top: 20px;
      margin-right: 10px;
    }

    span {
      font-size: 16px;
      display: inline-block;
      font-weight: 500;
      color: #0077FF;
      position: relative;
      bottom: 3px;
    }

    label {
      display: block;
      float: right;
      height: 15px;
      font-size: 15px;
      margin-top: 21px;
      color: #999999;
      cursor: pointer;
    }
  }

  .sv-ct {
    padding-top: 19px;
    cursor: pointer;

    p {
      font-size: 16px;
      color: #222222;
      line-height: 36px;

      span {
        color: #0077FF;
      }
    }

    p:hover {
      color: #0077FF;
    }
  }

  .service-item-right {
    margin-right: 0;
  }
}

.trends {
  background: #F5F5F5;
  .trendsWrap{
   width: 1160px;
   margin:40px auto;
   background: #fff;
   min-height: 180px;
  clear: both;
  padding: 30px 20px;
  overflow: hidden;
  }

  .trends-title {
    height: 40px;
    border-bottom: 1px solid #D9D9D9;
    span{
      color: #0071fb;
      font-size: 20px;
      border-bottom:2px solid #0071fb ;
      padding-bottom: 15px;
    }
    label{
      display: block;
      float: right;
      color: #999999;
      cursor: pointer;
    }
  }

  .trends-item {
    width: 580px;
    height: 110px;
    margin-top: 20px;
    float: left;

    img {
      width: 160px;
      height: 110px;
      float: left;
    }

    .td-ct {
      width: 380px;
      float: left;
      margin-left: 20px;

      h4 {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #222222;
      }

      .td-des {
        margin-top: 13px;
        font-size: 16px;
        height: 48px;
        overflow: hidden;
        color: #666666;
        line-height: 24px;
        margin-bottom: 4px;
      }

      span {
        font-size: 14px;
        color: #666666;
      }
    }

    .td-ct:hover {
      h4 {
        color: #0077FF !important;
      }

      cursor: pointer;
    }
  }

  .moreP {
    position: relative;
    top: 25px;
  }
}

.siderBar {
  position: fixed;
  bottom: 160px;
  cursor: pointer;
  width: 65px;
  right: 24px;
  height: 200px;
  background: #FFFFFF;
  border-radius: 6px;

  .siderBar-wrap {
    position: relative;
  }

  .custorBox {
    position: absolute;
    right: 65px;
    top: -110px;
    width: 360px;

    .custorCt {
      min-height: 360px;
      background: #fff;
      border-radius: 6px;
      margin-right: 15px;
      position: relative;
      padding-bottom: 5px;
    }

    .contact {
      position: relative;

      img {
        width: 345px;
        height: 30px;
      }

      span {
        position: absolute;
        left: 145px;
        top: 6px;
        color: #e7ba88;
        font-size: 13px;
      }
    }

    .custorPhone {
      height: 80px;
      margin: 10px 20px;
      padding-left: 20px;
      border-bottom: 1px solid #F5F5F5;

      img {
        width: 60px;
        float: left;
      }

      p {
        float: left;
        margin-left: 20px;
      }

      span {
        display: block;
        margin-top: 10px;
      }
    }

    .wxCode {
      border-bottom: 1px solid #F5F5F5;
      padding-bottom: 10px;
      padding-top: 5px;

      img {
        width: 200px;
        height: 200px;
        margin-left: 75px;
      }
    }

    .workTime {
      margin: 20px;
      font-size: 14px;
      color: #666;
    }
  }

  .triangle {
    width: 0;
    height: 0;
    border-left: 10px solid #fff;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    position: absolute;
    right: -10px;
    top: 190px;
  }

  .siderBar-item {
    text-align: center;
    height: 60px;

    img {
      margin-top: 20px;
    }

  }
}
</style><style lang="scss">
.banner-top {
  .el-carousel__container {
    z-index: 0;
  }
}
</style>
